import { Link } from "gatsby";
import React from "react";

import * as styles from "./terms.module.scss";

const Terms = () => {
  return (
    <div className={styles.container}>
      <h1>TERMS OF USE</h1>

      <p>
        PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT
        INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE
        INCLUDE VARIOUS DISCLAIMERS, LIMITATIONS AND EXCLUSIONS, AND A DISPUTE
        RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
      </p>
      <p>
        Access and use of this website petroninja.com and its subsites (the "
        <b>Website</b>") and its related services (the "<b>Services</b>"), in
        are provided by Stack Technologies Ltd. ("<b>Petro Ninja</b>", "
        <b>we</b>" and "<b>us</b>") to you on condition that you accept these
        Terms of Use, and by accessing or using this Website or the Services,
        you agree to these Terms of Use. If you do not agree to accept and abide
        by these Terms of Use you may not access or use this Website or the
        Services.
      </p>
      <p>
        These Terms of Use govern the relationship between you, the Website
        visitor and/or member ("
        <b>you</b>"), and Petro Ninja with respect to your use of this Website
        and its related Services. You agree that the agreement formed by these
        Terms of Use is like any written negotiated agreement signed by you, and
        you agree to be bound by, and fully comply with, its terms. You
        represent and warrant that you have all necessary right, power and
        authority to enter into this agreement and to perform and otherwise
        discharge all of your obligations hereunder. If you are an agent or
        employee of an entity you represent and warrant that (i) the individual
        accepting this agreement is authorized to accept these Terms of Use on
        such entity's behalf and to bind such entity, and (ii) such entity has
        full power, corporate or otherwise, to enter into and be bound by these
        Terms of Use.
      </p>
      <p>
        We may modify the Terms of Use, or any part thereof, or add or remove
        terms at any time, and such modifications, additions or deletions will
        be effective immediately upon posting. Your access and use of the
        Website after such posting shall be deemed to constitute acceptance by
        you of such modifications, additions or deletions. If you do not agree
        with any revision, you must stop using and accessing the Website.
      </p>

      <h2>1. JURISDICTION</h2>
      <p>
        You understand that: (i) aspects of this Website and the Services may
        not be available in all jurisdictions; and (ii) you are responsible for
        ensuring that it is lawful for you to access and use this Website and
        Services in your jurisdiction.
      </p>
      <p>
        If you are residing in a jurisdiction which restricts the use of
        Internet-based applications according to age, or which restricts the
        ability to enter into agreements such as set out in these Terms of Use
        according to age and you are under such a jurisdiction and under such
        age limit, you may not enter into this agreement or use this Website or
        the Services.
      </p>
      <p>
        Furthermore, if you are residing in a jurisdiction where it is forbidden
        by law to participate in the activities offered by this Website or the
        Services, you may not enter into this agreement or use this Website or
        the Services. By using this Website you are explicitly stating that you
        have verified in your own jurisdiction that your use of this Website and
        the Services is allowed.
      </p>
      <p>
        Your Account (as defined below) may be deleted and these Terms of Use
        may be suspended or terminated without warning, if we believe that you
        are under age or your use of this Website or the Services (including the
        purchase of Products) is not allowed in your jurisdiction of residence.
      </p>

      <h2>2. PRIVACY</h2>
      <p>
        Petro Ninja is committed to respecting the privacy of the personal
        information of the individuals with whom we interact. We have developed
        an External Privacy Policy to describe our privacy policies and
        practices and how we collect, use and disclose the personal information
        of those individuals who visit this Website or use the Services. Please
        see our <Link to="/privacy">External Privacy Policy</Link> for further
        details.
      </p>
      <p>
        You acknowledge and agree that access to and use of this Website and the
        Services is provided via the Internet and that your information,
        including personal information, may be transferred across national
        borders and stored or processed in any country in the world.
      </p>

      <h2>3. ACCOUNTS</h2>
      <p>
        In order to access and use certain Services available on this Website
        (including the purchase of Products), you may need to sign up for, open
        and maintain an account ("
        <b>Account</b>"). During the registration process we may collect your
        name, address, email address, phone number, credit card information,
        username, and password. Your information may be used by us:
      </p>
      <ul>
        <li>
          to verify your eligibility to create and to maintain your Account;
        </li>
        <li>
          to verify your eligibility to create and to maintain your Account;
        </li>
        <li>
          to enable us to provide certain security and privacy safeguards;
        </li>
        <li>to deal with security, debugging and technical support issues;</li>
        <li>for billing and payment-related issues;</li>
        <li>to protect ourselves and others from abuse; and</li>
        <li>to comply with laws.</li>
      </ul>
      <p>
        All of the information you provide to us or that we collect from you in
        connection with your use of the Website or the Services will be governed
        by these Terms of Use. You agree at all times to: (i) provide accurate,
        current and complete information about yourself as prompted by our
        registration form or otherwise; and (ii) maintain and update your
        information (including your email address) to keep it accurate, current
        and complete. You acknowledge that, if any information provided by you
        is untrue, inaccurate, not current or incomplete, we may suspend or
        terminate your Account and your ability to use this Website or the
        Services and, in our sole discretion, to report you to the appropriate
        authorities.
      </p>
      <p>
        We hereby disclaim any and all responsibility or liability for any
        unauthorized use of your Account.
      </p>
      <p>
        You must not choose a username that infringes the rights of any third
        party, impersonates any Petro Ninja employee, other users, celebrity or
        anyone with a considerable fame, which is deliberately confusing or
        which is offensive, racist, obscene, hurtful, unlawful or otherwise
        inappropriate. You agree that you will not use misspellings or
        alternative spellings or take any other actions for the purpose of
        circumventing the foregoing restrictions. You understand and agree that,
        in addition to the foregoing restrictions, we reserve the right to
        change, remove, alter or delete any username at any time and for any
        reason in our sole discretion.
      </p>
      <p>
        YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR
        USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES (INCLUDING
        PURCHASES AND CHARGES, AS APPLICABLE) THAT ARE CONDUCTED THROUGH YOUR
        ACCOUNT.
      </p>
      <p>
        You cannot transfer your Account to any other person, or permit anyone
        to use same.
      </p>
      <p>
        You agree to notify us immediately of any unauthorized use, theft or
        misappropriation of your account, username, or password. We shall not be
        liable for any loss that you incur as a result of someone else using
        your username or password, either with or without your knowledge.
      </p>

      <h2>4. PETRO NINJA SOLUTION</h2>
      <p>
        The Services include the limited use the Petro Ninja's proprietary data
        and mapping web application (the "<b>Petro Ninja Solution</b>"). Subject
        to the terms and conditions of this Agreement, Petro Ninja hereby grants
        you a non-exclusive, revocable, non-assignable, personal license to use
        the portions of the Petro Ninja Solution that are made available to you
        on the Website for your internal business operations. Unless you enter
        into a separate software-as-a-service agreement ("<b>SaaS Agreement</b>
        "), we will allow you to upload up to a maximum of ten (10) of your
        photographs and/or forms onto the Petro Ninja Solution (the "
        <b>User Content</b>") for the limited purposes of: (i) viewing such
        information through the Petro Ninja Solution; and (ii) potentially
        sharing such information with specified users of the Petro Ninja
        Solution. Additional Services are available on paid-subscription basis,
        subject to your entering into our SaaS Agreement (
        <a href="https://petroninja.com/saas">https://petroninja.com/saas</a>)
        and our acceptance of an order form submitted by you (either through the
        Website or through such other means as we may determine from time to
        time).
      </p>
      <p>
        As between the parties, Petro Ninja shall exclusively own all right,
        title and interest in and to the Website and the Services (including,
        without limitation, the Petro Ninja Solution) and all copies, portions,
        improvements, and derivative works thereof (by whomever produced) and
        all copyright rights, patent rights, trademark rights, trade secret
        rights and all other intellectual property and proprietary rights
        therein anywhere in the world related thereto.
      </p>
      <p>
        The Petro Ninja Solution relies upon data obtained from third party
        sources ("
        <b>Third Party Data</b>"), including government agencies. You are hereby
        notified that certain element of the Third Party Data may be obtained
        directly from such government agencies (e.g. Alberta Energy Regulator).
        Without limiting the generality of the disclaimer at Section 13, Petro
        Ninja makes no representations or warranties that the Third Party Data
        is accurate, current, complete, or will be updated by the on a periodic
        basis. Accordingly, under no circumstances should you rely on the Petro
        Ninja Solution in making any commercial, financial or other decisions.
        Further, the Third Party Data may contain information licensed under and
        subject to the Open Government License – British Columbia (
        <a href="https://www.bcogc.ca/online-services">
          https://www.bcogc.ca/online-services
        </a>
        ) (contains information licensed under the Open Government License –
        British Columbia.).
      </p>

      <h2>5. RESTRICTIONS</h2>
      <p>
        You shall not: (i) upload to, or transmit from, the Website any data,
        file, software, or link that contains or redirects to a virus, Trojan
        horse, worm, or other harmful component or content; (ii) reverse
        engineer, decompile, hack, disable, interfere with, disassemble, copy,
        or disrupt the integrity or the performance of the Website or the
        Services, or third party use of the Website, or any third party data
        contained therein; (iii) access the source code of the Website or
        Service or do anything that will reveal or generate same; (iv) access or
        use the Website or the Services in order to build a competitive product
        or service or copy any ideas, features, or functions of the Website or
        the Services; (v) attempt to gain unauthorized access to the Website or
        its related systems or networks or any third party systems or networks;
        (vi) create derivatives or modify the Website or the Services; (vii)
        sublicense, lend or lease the Website or the Services, or otherwise make
        the Website or the Services available, to any third party; (viii) permit
        the Website or the Services to be subject to any timesharing, service
        bureau, subscription service or rental activities; (ix) grant or purport
        to grant any third party any license or other right, title, security or
        other interest, lien or option in or to the Website or the Services; (x)
        use the Website or the Services for any unlawful or illegal activity; or
        (xi) authorize, permit, or encourage any third party to do any of the
        foregoing.
      </p>

      <h2>6. CODE OF CONDUCT</h2>
      <p>
        As above, you may not interfere with the security of, or otherwise abuse
        this Website, the Services or any system resources, services or networks
        connected to or accessible through this Website or the Services. You may
        only use this Website and the Services for lawful purposes. You agree
        that you will not attempt to, nor permit any third party to, enter
        restricted areas of Petro Ninja's computer systems or perform functions
        that you are not authorized to perform pursuant to these Terms of Use.
      </p>
      <p>
        We may, without notice, temporarily suspend your access to the Website
        and/or the Services by deactivating any passwords and/or Internet links
        to the Services if we reasonably suspect that you or any other party are
        obtaining unauthorized access to the Website, the Services, or any of
        our other systems, networks, documentation or information, or are using
        otherwise valid passwords (for the Services) in any other unauthorized
        manner. These suspensions will be for such periods of time as we, at our
        sole discretion, may reasonably determine is necessary to permit the
        thorough investigation of such suspended activity. Notwithstanding any
        other provision in this Agreement, we may terminate this Agreement, or
        any portion of the permissions granted herein, immediately, without
        notice, if it is determined that you have: (i) undertaken any
        unauthorized activity as described in Section 5 or 6; or (ii) failed to
        comply with any applicable laws.
      </p>
      <p>
        While using this Website or the Services you agree to comply with all
        applicable laws, rules and regulations. We reserve the right, in our
        sole discretion, to take any actions we deem necessary and appropriate
        to preserve the integrity of this Website and the Services.
      </p>
      <p>
        You agree that you will not upload or transmit any User Content that:
      </p>
      <ul>
        <li>
          infringes any patent, trademark, trade secret, copyright or other
          proprietary rights of any party;
        </li>
        <li>
          is patently offensive or promote racism, bigotry, hatred or physical
          harm of any kind against any group or individual;
        </li>
        <li>harasses or advocates harassment of another person;</li>
        <li>
          contains nudity, violence, or offensive subject matter or contain a
          link to an adult website;
        </li>
        <li>
          solicits any personal information from anyone under eighteen (18)
          years of age;
        </li>
        <li>
          provides any telephone numbers, street addresses, last names, URLs or
          email addresses to anyone (without sufficient consent);
        </li>
        <li>
          promotes information that you know is false or misleading or promotes
          illegal activities or conduct that is abusive, threatening, obscene,
          defamatory or libelous;
        </li>
        <li>
          promotes any illegal or unauthorized copy of another person's
          copyrighted work;
        </li>
        <li>
          contains restricted or password-only access pages or hidden pages or
          images;
        </li>
        <li>
          furthers or promotes any criminal activity or enterprise or provide
          instructional information about illegal activities; or
        </li>
        <li>
          includes a photograph or image of another person that you have posted
          without that person's consent.
        </li>
      </ul>

      <h2>7. BACKUP</h2>
      <p>
        You will be exclusively responsible for securing and backing-up the User
        Content or any other content that you upload onto the Website. You agree
        that Petro Ninja shall not be responsible or liable for the deletion,
        loss, or failure to store any such data and other communications
        maintained or transmitted through use of the Services.
      </p>

      <h2>8. AVAILABILITY</h2>
      <p>
        While we endeavour to keep downtime to a minimum, we can't promise that
        this Website or the Services will be uninterrupted, secure or
        error-free. We reserve the right to interrupt/suspend this Website or
        the Services, or any part thereof, with or without prior notice for any
        reason.
      </p>

      <h2>9. ACCESS TO THE INTERNET</h2>
      <p>
        You are solely responsible for obtaining and maintaining all Internet,
        computer hardware and other equipment needed to access and use this
        Website and the Services and that you shall be solely responsible for
        all charges and fees related thereto.
      </p>

      <h2>10. CURRENCY OF WEBSITE</h2>
      <p>
        You acknowledge and agree that access to this Website and the Services
        may not be available from time to time, may be amended, revised,
        replaced, suspended or terminated in whole or in part at any time and
        without notice, and that Petro Ninja shall not, in any event, be
        responsible to you in any way should you be unable to access this
        Website and the Service at any time or from time to time. For the
        avoidance of doubt, we reserve the right at any time to change: (i) the
        terms and conditions of these Terms of Use and/or the SaaS Agreement;
        (ii) this Website or the Services, including terminating, eliminating,
        supplementing, modifying, adding or discontinuing any content or feature
        or data or service on or available through this Website or the Services
        or the hours that they are available; (iii) any fees or charges, if any,
        related to the use of this Website or the Services; and (iv) the
        equipment, hardware or software required to use and access this Website
        or the Services.
      </p>
      <p>
        Any changes we make to these Terms of Use and/or the SaaS Agreement will
        be effective immediately upon notice, which we may provide by any means
        including, without limitation, posting on this Website. Your continued
        use of this Website or the Services after such notice will be deemed
        acceptance of such changes. Be sure to return to this Website
        periodically to ensure you are familiar with the most current version of
        these Terms of Use.
      </p>

      <h2>11. LINKED WEBSITES</h2>
      <p>
        This Website or the Services may provide links to third party websites
        for your convenience only. The inclusion of these links does not imply
        that Petro Ninja monitors or endorses these websites. Petro Ninja does
        not accept any responsibility for such websites. Petro Ninja shall not
        be responsible or liable, directly or indirectly, for any damage or
        loss, caused or alleged to be caused by or in connection with the use of
        or the reliance upon any information, content, goods or services
        available on or through any third party websites or linked resources.
      </p>

      <h2>INTERNET SOFTWARE OR COMPUTER VIRUSES</h2>
      <p>
        Petro Ninja shall not be responsible or liable for any software,
        computer viruses or other destructive, harmful or disruptive files or
        programs that may infect or otherwise impact your use of your computer
        equipment or other property on account of your access to, use of, or
        browsing on this Website or the Services or other content from this
        Website. Petro Ninja recommends that you install appropriate anti-virus
        or other protective software.
      </p>

      <h2>13. DISCLAIMER</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE WEBSITE AND THE
        SERVICES ARE PROVIDED TO YOU "AS IS" AND ON AN "AS AVAILABLE" AND "WITH
        ALL FAULTS" BASIS WITHOUT ANY REPRESENTATION, WARRANTY, OR GUARANTEE OF
        ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
        LIMITATION, ANY REPRESENTATION, WARRANTY, OR GUARANTEE OF
        MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING
        THE GENERALITY OF THE FOREGOING, PETRO NINJA DOES NOT WARRANT, REPRESENT
        OR GUARANTEE THAT THE WEBSITE OR THE SERVICES WILL: (I) BE UNINTERRUPTED
        OR ERROR-FREE; (II) MEET YOUR NEEDS; OR (III) FUNCTION WITH, AND NOT
        DAMAGE, ANY OTHER SOFTWARE OR HARDWARE OR OPERATE WITHOUT INTERRUPTION.
      </p>
      <p>
        NO INFORMATION PROVIDED BY ANY REPRESENTATIVE OF PETRO NINJA SHALL
        CREATE ANY REPRESENTATION, WARRANTY, OR GUARANTEE OF ANY KIND WHATSOEVER
        BY PETRO NINJA OR OTHERWISE MODIFY THIS AGREEMENT. YOU ACKNOWLEDGE AND
        AGREE THAT YOUR ACCESS AND/OR USE OF THE WEBSITE AND THE SERVICES IS AT
        YOUR SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
        YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS THEREFROM. TO THE
        MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PETRO NINJA WILL HAVE NO
        WARRANTY OBLIGATION WHATSOEVER WITH RESPECT TO THE WEBSITE OR THE
        SERVICES. FOR THE AVOIDANCE OF DOUBT, YOU ACKNOWLEDGE AND AGREE THAT THE
        WEBSITE AND THE SERVICES ARE INTENDED TO BE USED BY TRAINED PERSONNEL
        AND ARE NOT A SUBSTITUTE FOR PROFESSIONAL JUDGMENT.
      </p>

      <h2>14. LIMITATION OF LIABILITY</h2>
      <p>
        YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS WEBSITE AND THE
        SERVICES.
      </p>
      <p>
        IN NO EVENT SHALL PETRO NINJA, ITS LICENSORS AND SUPPLIERS, OR ANY OF
        THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS,
        OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY,
        CONSEQUENTIAL OR OTHER SIMILAR DAMAGES WHATSOEVER (OR FOR ANY DAMAGES
        FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION), OR ANY
        OTHER PECUNIARY LOSS IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION,
        SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE,
        INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR
        EXPLOITATION OF THIS WEBSITE, THE SERVICES (INCLUDING THE PETRO NINJA
        SOLUTION), OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER,
        EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER
        THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE),
        INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.
      </p>
      <p>
        IN EVERY EVENT, PETRO NINJA'S TOTAL MAXIMUM AGGREGATE LIABILITY UNDER
        THESE TERMS OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PART OF THIS
        WEBSITE AND/OR THE SERVICES AND/OR ANY PRODUCTS, IN ANY MANNER
        WHATSOEVER, SHALL BE LIMITED TO THE GREATER OF: (I) FIVE ($5.00)
        DOLLARS; AND (II) THE FEES ACTUALLY PAID BY YOU TO ACCESS AND USE THE
        SERVICES.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF
        LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
        LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </p>

      <h2>15. INDEMNIFICATION</h2>
      <p>
        YOU AGREE TO, UPON REQUEST, INDEMNIFY AND HOLD US HARMLESS FROM ANY AND
        ALL LIABILITIES, CLAIMS, LOSSES AND EXPENSES, INCLUDING ATTORNEYS' FEES,
        WHICH ARISE DIRECTLY OR INDIRECTLY FROM ANY BREACH OF THESE TERMS OF USE
        FOR WHICH YOU ARE RESPONSIBLE.
      </p>

      <h2>16. TERM & TERMINATION</h2>
      <p>
        This Agreement will become effective on the date you agree to these
        Terms of Use or otherwise access or use the Website for any purpose, and
        will remain in force until the Agreement is terminated hereunder.
      </p>
      <p>
        You may terminate this Agreement upon thirty (30) days' notice to Petro
        Ninja.
      </p>
      <p>
        Petro Ninja may, at its sole discretion, at any time and from time to
        time, without notice, suspend your right to use this Website and the
        Services and/or terminate these Terms of Use (including any of the
        licenses granted hereunder). Without limiting the foregoing, these Terms
        of Use and the licenses granted herein shall automatically terminate,
        without notice, if you materially fail to perform or comply with these
        Terms of Use or any provision hereof. Upon termination of these Terms of
        Use, you shall immediately cease and desist from all use of this Website
        and the Services.
      </p>
      <p>
        Sections 5, 13 to 15, and 17 to 19 will survive any termination or
        expiry of these Terms of Use.
      </p>

      <h2>17. NOTICE</h2>
      <p>
        Any notices to Petro Ninja under this Agreement shall be sent by email
        to <a href="mailto:info@petroninja.com">info@petroninja.com</a>. Petro
        Ninja may send you notices via the Website or to any email address you
        may have registered with Petro Ninja in connection with the Services.
        Each notice shall be deemed given and effective one (1) day after it is
        sent, or earlier if actually received earlier by the recipient, unless
        the sending party is notified that the email address is invalid or the
        transmission was not successful.
      </p>

      <h2>18. YOUR COMMENTS</h2>
      <p>
        Feel free to email your comments, suggestions and feedback ("
        <b>Comments</b>") to Petro Ninja at the email addresses provided in this
        Website or the Services. However, the Internet is not a fully secure
        medium and any communication may be lost, intercepted or altered. Petro
        Ninja is not liable for any damages related to communications to or from
        this Website or the Services. You agree with respect to any Comments
        provided by you to us, via email or otherwise, that: (i) Petro Ninja has
        no obligation concerning such Comments; (ii) such Comments are
        non-confidential; (iii) Petro Ninja may use, disclose, distribute or
        copy such Comments and use any ideas, concepts or know-how contained in
        such Comments for any purpose and without restriction or obligation to
        you (or any third party); and (iv) such Comments are truthful and do not
        violate the legal rights of others.
      </p>

      <h2>19. MISCELLANEOUS</h2>
      <p>
        ENTIRE AGREEMENT: These Terms of Use (which incorporates by reference
        our <Link to="/privacy">External Privacy Policy</Link>) and, if
        applicable, the SaaS Agreement set out the entire agreement between us
        relating to the subject matter herein and supersede and any all prior or
        contemporaneous written or oral agreements between us.
      </p>
      <p>
        FORCE MAJEURE: Except for each party's obligations to pay money, neither
        party shall be deemed to be in breach of this Agreement for any failure
        or delay in performance caused by reasons beyond its reasonable control,
        including but not limited to acts of God, earthquakes, wars, terrorism,
        communication failures, strikes or shortages of materials.
      </p>
      <p>
        THIRD-PARTY SERVICES: The Services may present links to third-party web
        sites or third-party services not owned or operated by us. We are not
        responsible for the availability of these third-party sites or services
        or their contents. You agree that we are not responsible or liable,
        directly or indirectly, for any damage or loss caused by or in
        connection with your use of or reliance on any content of any such
        third-party site or services or goods or services available through any
        such third-party site or services.
      </p>
      <p>
        INTERPRETATION: You represent that you have read and fully understand
        this Agreement. No provision shall be construed against a party by
        reason of the fact that such party or its legal counsel drafted that
        provision, notwithstanding any rule of law or any legal decision to the
        contrary. For purposes of interpreting this Agreement, headings
        contained herein are for convenience of reference only and shall not
        affect the interpretation of this Agreement and, whenever the context
        requires, the singular number will include the plural, and vice versa.
        Nothing in this Agreement shall restrict the right of Petro Ninja to
        engage in any business or provide any software, services, or other
        products to any third parties on any terms.
      </p>
      <p>
        GOVERNING LAW: These Terms of Use is governed by and construed in
        accordance with the laws of the Province of Alberta, Canada, without
        regards to its principles of conflicts of law. Subject to the obligation
        to arbitrate below, you agree to personal jurisdiction of the courts
        located in located in Calgary, Alberta, and waive any jurisdictional,
        venue, or inconvenient forum objections to such courts.
      </p>
      <p>
        ARBITRATION: Except where prohibited by applicable law, any controversy,
        claim or dispute arising out of or relating to these Terms of Use, the
        Website or the Services or the relationship which results from these
        Terms of Use, including without limitation, the performance, breach,
        enforcement, existence or validity of the matters provided for in these
        Terms of Use or your receipt and use of the Website or Services, which
        cannot be amicably resolved, (collectively, a "Claim"), will be referred
        to and finally settled (to the exclusion of the courts) by private and
        confidential binding arbitration before a single arbitrator held in
        Calgary, Alberta in English and in accordance with the Arbitration Act
        (Alberta), except as such rules are modified or waived herein. Although
        the appointing authority shall be a Justice of the Court of Queen's
        Bench, the arbitrator will be a person who is legally trained and who
        has experience in the information technology field in Canada or the
        United States of America and is independent of either party. Any such
        Claim will be arbitrated on an individual basis, and will not be
        consolidated in any arbitration with any claim, controversy or dispute
        of any other party. Except where prohibited by applicable law, you agree
        to: (i) waive any right you may have to commence or participate in any
        class action against Petro Ninja relating to any Claim; (ii) opt out of
        any class proceedings against Petro Ninja; and (iii) waive, to the
        fullest extent permitted by law, any right of appeal. Notwithstanding
        the foregoing, Petro Ninja reserves the right to pursue the protection
        of intellectual property rights and confidential information through
        injunctive or other equitable relief through the courts.
      </p>
      <p>
        ASSIGNMENT: These Terms of Use are not assignable, transferable or
        sub-licensable by you except with Petro Ninja's prior written consent.
        Petro Ninja may assign its rights and obligations under Terms of Use at
        its discretion. No waiver by either party of any breach or default
        hereunder shall be deemed to be a waiver of any preceding or subsequent
        breach or default.
      </p>
      <p>
        SEVERABILITY: If any provision of these Terms of Use is found to be
        unlawful, void, or for any reason unenforceable, then that provision
        shall be deemed severable from these Terms of Use and shall not affect
        the validity and enforceability of any remaining provisions.
      </p>

      <br />
      <p>These Terms of Use were last updated on January 9, 2023.</p>
    </div>
  );
};

export default Terms;
