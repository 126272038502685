import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Terms from "../components/Terms";

export default function TermsPage({ data }) {
  const footerData = data.footer.frontmatter;

  return (
    <div>
      <Helmet defer={false}>
        <title>Terms of Use</title>
      </Helmet>
      <Terms />
      <Footer footerData={footerData} />
    </div>
  );
}

export const query = graphql`
  query TermsQuery {
    footer: markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
      frontmatter {
        footerLine1
        mailTo
        telephone
      }
    }
  }
`;
